
<!-- Copyright IBM Deutschland GmbH 2023. All rights reserved. -->

<div class="slider-outer-wrapper">
    <div class="flex justify-end gap-10 slider-wrapper">
        <p class="small">aA</p>
    
        <mat-slider invert vertical [min]="minValue" [max]="maxValue" [step]="stepValue" color="primary">
            <input matSliderThumb value="16" [value]="baseFontSize" (input)="changeFontSize($event)" id="fontSizeSlider"/>
        </mat-slider>
        
        <p class="large">aA</p>
    </div>
</div>