// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { NgModule } from '@angular/core'
import { MatIconModule } from "@angular/material/icon"
import { MatButtonModule } from "@angular/material/button"
import { MatSliderModule } from '@angular/material/slider'
import { MatTooltipModule } from "@angular/material/tooltip"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { MatExpansionModule } from "@angular/material/expansion"

/***********************************************************************************************
decorators
***********************************************************************************************/

@NgModule({
    imports: [
        MatIconModule,
        MatSliderModule,
        MatButtonModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatExpansionModule,
    ],

    exports: [
        MatIconModule,
        MatSliderModule,
        MatButtonModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatExpansionModule,
    ]
})

/***********************************************************************************************
class MaterialModule
***********************************************************************************************/

export class MaterialModule {}
