
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Injectable, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'


/***********************************************************************************************
decorators
***********************************************************************************************/
@Injectable({
    providedIn: 'root'
})

/***********************************************************************************************
class TranslateService
***********************************************************************************************/

export class TranslateService {

    // members
    /*-----------------------------------------------------------------------------------*/

    current: string // the current language string (like "de")
    default: string // the default
    data: Object // will hold the language object holding all the strings

    // services
    /*-----------------------------------------------------------------------------------*/

    private readonly _http = inject(HttpClient)

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor() {
        // defaults
        this.default = "de"
        this.data = {}
    }

    // public
    /*-----------------------------------------------------------------------------------*/

    /** 
     * setting a specific language options
     * @param  {string} lang
     * @returns Promise
     */
    use(lang: string): Promise<{}> {
        
        this.current = lang

        return new Promise((resolve, reject) => {
            
            const langPath = `assets/i18n/${lang || this.default}.json`
            
            this._http.get(langPath).subscribe(
                translation => {
                    this.data = Object.assign({}, translation || {})
                    resolve(this.data)
                },
                error => {
                    this.data = {}
                    resolve(this.data)
                }
            )
        })
    }
}
