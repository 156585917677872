
<!-- Copyright IBM Deutschland GmbH 2023.  All rights reserved. -->
<app-full-height-element>
    <div class="center-container">
        <div>
            <div class="wizard-widget-container">
                <div class="inner-center-container">
                    <div>
                        <!-- title row-->
                        <div class="flex space-between gap-16 title-row">
                            <!-- title -->
                            <h1>{{ 'help-title-1' | translate }}<span class="color-primary">{{subject}}</span></h1>
                            <!-- help button -->
                            <button mat-flat-button class="default-button circled back-button" (click)="goBack()"><span class="material-icons color-white">arrow_back</span></button>
                        </div>
    
                        <!-- sub-title -->
                        <h2 class="sub-title">{{ 'help-sub-title' | translate }}</h2>
    
                        <!-- the boxes -->
                        <div class="flex space-between flex-1 flex-wrap help-box-wrapper flex-fill">
                            <div class="help-box flex-col items-center justify-start" (click)="makeCall()">
                                <mat-icon>phone_enabled</mat-icon>
                                <h2 class="help-title">{{ 'help-phone-title' | translate }}</h2>
                                <p class="content-string">{{ 'help-phone-content' | translate }}</p>
                            </div>
                            <div class="help-box flex-col items-center justify-start" (click)="sendEmail()">
                                <mat-icon>mail</mat-icon>
                                <h2 class="help-title">{{ 'help-mail-title' | translate }}</h2>
                                <p class="content-string">{{ 'help-mail-content' | translate }}</p>
                            </div>
                            <div class="help-box flex-col items-center justify-start ">
                                <mat-icon>person</mat-icon>
                                <h2 class="help-title">{{ 'help-local-title' | translate }}</h2>
                                <p class="content-string">{{ 'help-local-content-1' | translate }}</p>
                                <p class="content-string">{{ 'help-local-content-2' | translate }}</p>
                                <p class="content-string">{{ 'help-local-content-3' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <button mat-flat-button class="default-button circled logout-button" (click)="logout()"><span class="material-icons color-white">logout</span></button> -->
                </div>
            </div>
        </div>
    </div>    
</app-full-height-element>
