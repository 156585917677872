
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Injectable, inject } from '@angular/core'
import { TranslateService } from 'src/app/services/translate.service'

/***********************************************************************************************
constants
***********************************************************************************************/

// the braking point to determine if we are on a small or a wirde screen
const SMALL_WIDTH_BREAKPOINT = 599

/***********************************************************************************************
decorators
***********************************************************************************************/

// providedIn: 'root' specifies that Angular should provide the service in the root injector
@Injectable({
    providedIn: 'root'
})

/***********************************************************************************************
class GlobalUiService
***********************************************************************************************/

export class GlobalUiService {

    // members
    /*-----------------------------------------------------------------------------------*/

    private _logOutputs: boolean
    private _mediaMatcher: MediaQueryList
    private _logComponents: boolean
    private _logConstructions: boolean

    // services
    /*-----------------------------------------------------------------------------------*/

    private readonly _translate = inject(TranslateService)

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor() {
        this._init()
    }

    // private
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * module initilization
     * @returns void
     */
    private _init(): void {

        // options to determine what needs to be logged into the console
        this._logOutputs = true
        this._logComponents = false
        this._logConstructions = false

        // is being updated when a resize occures
        this._mediaMatcher = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)

        // loggs the construction of the class
        this.logMessage('service "global-ui" is constructed', 2)
    }

    // public
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * tells us if this is running opn a small screen or not
     * @returns boolean
     */
    isScreenSmall(): boolean {
        return this._mediaMatcher.matches
    }

    /**
     * @param  {string} lang the language string -> should be identical to the filenames of the löanguage file (i.e "de")
     */
    setLang(lang: string) {
        this._translate.use(lang)
    }

    /**
     * function to set the languge value
     */
    switchLang() {

        var nextLang = ''

        switch (this._translate.current) {
            case "de":
                nextLang = 'en'
                break
        
            default:
                nextLang = 'de'
        }

        this.setLang(nextLang)
    }

    /**
     * used to output a message in the console
     * @param  {string} message the string to be displayed
     * @param  {number} indent indentation of the string
     * @returns void
     */
    logMessage(message: string, indent: number): void {
        
        if (this._logOutputs) {
            if (message.indexOf('component') > -1 && !this._logComponents) return
            if (message.indexOf('constructed') > -1 && !this._logConstructions) return

            let prefix: string = ''
            prefix += '-'.repeat(indent)
            prefix += '>'

            message = message.toUpperCase()

            console.log(`${prefix} ${message}`)
        }
    }
}
