
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { KeycloakService } from "keycloak-angular"
import { environment } from 'src/environments/environment'

/***********************************************************************************************
exported funtions / objects
***********************************************************************************************/

/**
 * factory used for the keycloak authentication
 * @param  {KeycloakService} keycloak
 */
export function keycloakInit(keycloak: KeycloakService) {
    
    return async () => {
        await keycloak.init({
            config: {
                realm: environment.keycloak.realm,
                url: environment.keycloak.uri,
                clientId: environment.keycloak.client_id,
            },
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                  window.location.origin + '/assets/files/silent-check-sso.html'
            },
            // loadUserProfileAtStartUp: true,
            enableBearerInterceptor: true,
            bearerExcludedUrls: ['/assets', '/clients/public']
        })
        .then(
            res => {
                const keycloakAuth = keycloak.getKeycloakInstance()
                keycloakAuth.onTokenExpired = () => keycloakAuth.refreshToken ? keycloakAuth.updateToken(5) : keycloakAuth.login()
            },
            err => console.log(err)
        )
        .finally( () => true )
    }
}
