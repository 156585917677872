
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/


import { AppModule } from 'src/app/app.module'
import { enableProdMode } from '@angular/core'
import { environment } from 'src/environments/environment'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

/***********************************************************************************************
action!
***********************************************************************************************/

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err))
