
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Pipe, PipeTransform, inject } from '@angular/core'
import { TranslateService } from 'src/app/services/translate.service'

/***********************************************************************************************
decorators
***********************************************************************************************/

@Pipe({
    name: 'translate',
    pure: false
})

/***********************************************************************************************
class TranslatePipe
***********************************************************************************************/

export class TranslatePipe implements PipeTransform {

    // services
    /*-----------------------------------------------------------------------------------*/

    private readonly _translate = inject(TranslateService)

    // public
    /*-----------------------------------------------------------------------------------*/
    
    /**
     * gest the matching string based on a key
     * @param  {string} key
     * @returns string
     */
    transform(key: string): string {
        return this._translate.data[key] || key
    }
}
