
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { ChangeDetectionStrategy, Component, HostListener, inject  } from '@angular/core'
import { GlobalUiService } from 'src/app/services/global-ui.service'

/***********************************************************************************************
component
***********************************************************************************************/

@Component({
    selector: 'app-full-height-element',
    template: '<div [style.min-height.px]="viewportHeight" class="flex items-center justify-center"><ng-content></ng-content></div>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullHeightElementsComponent {

    // members
    /*-----------------------------------------------------------------------------------*/

    viewportHeight: number = window.innerHeight

    // services
    /*-----------------------------------------------------------------------------------*/
    private readonly _ui = inject(GlobalUiService)

    // constructor
  /*-----------------------------------------------------------------------------------*/

    constructor() {
        // loggs the construction of the class
        this._ui.logMessage('component "full-height-element" is constructed', 0)
    }

    // events
    /*-----------------------------------------------------------------------------------*/

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.viewportHeight = window.innerHeight
    }
}
