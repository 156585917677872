
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { NotFoundComponent } from 'src/app/areas/not-found/not-found.component'
import { HelpComponent } from 'src/app/areas/main-app/routes/help/help.component'

/***********************************************************************************************
constants
***********************************************************************************************/

const routes: Routes = [
    { path: '', loadChildren: () => import('./areas/login/login.module').then(m => m.LoginModule) },
    { path: 'login', loadChildren: () => import('./areas/login/login.module').then(m => m.LoginModule) },
    { path: 'app', loadChildren: () => import('./areas/main-app/main-app.module').then(m => m.MainAppModule) },
    { path: 'help/:subject', pathMatch: 'full', component: HelpComponent,},
    { path: '**', component: NotFoundComponent }
]

/***********************************************************************************************
decorators
***********************************************************************************************/

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})

/***********************************************************************************************
class AppRoutingModule
***********************************************************************************************/

export class AppRoutingModule { }
