
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Meta } from '@angular/platform-browser'
import { Component, inject } from '@angular/core'
import { GlobalUiService } from './services/global-ui.service'

/***********************************************************************************************
decorators
***********************************************************************************************/

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})

/***********************************************************************************************
class AppComponent
***********************************************************************************************/

export class AppComponent {
	
    // members
    /*-----------------------------------------------------------------------------------*/

    // just used to identify the staging area (adds a banner and stuff like that)
    isStaging: boolean = false

	// title of the app
	title = 'UMessenger Wizard'

    // services
    /*-----------------------------------------------------------------------------------*/

    public ui = inject(GlobalUiService)
    private readonly _meta = inject(Meta)

	// constructor
    /*-----------------------------------------------------------------------------------*/

	constructor() {
		
		window.addEventListener('beforeinstallprompt', (event) => {})

        // gets the value from the environment file
        // this.isStaging = environment.staging
	}

	 // public
    /*-----------------------------------------------------------------------------------*/
	
	/**
	 * initilization event
	 */
	ngOnInit() {
        // setting the theme color
        this._meta.updateTag({ content: 'white' }, 'name=theme-color')
	}
}
