
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { TranslatePipe } from 'src/app/pipes/translate.pipe'
import { MaterialModule } from 'src/app/shared-modules/material.module'
import { WizardComponent } from 'src/app/components/wizard/wizard.component'
import { HelpComponent } from 'src/app/areas/main-app/routes/help/help.component'
import { FontSliderComponent } from 'src/app/components/font-slider/font-slider.component'
import { WizardTitle } from 'src/app/components/wizard/wizard-title/wizard-title.component'
import { WizardSubtitle } from 'src/app/components/wizard/wizard-subtitle/wizard-subtitle.component'
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component'
import { FullHeightElementsComponent } from 'src/app/components/full-height-element/full-height-element.component'
import { WizardBulletListComponent } from 'src/app/components/wizard/wizard-bullet-list/wizard-bullet-list.component'
import { WizardRadioGroupComponent } from 'src/app/components/wizard/wizard-radio-group/wizard-radio-group.component'
import { WizardProgressBarComponent } from 'src/app/components/wizard/wizard-progress-bar/wizard-progress-bar.component'
import { WizardImagePresenterComponent } from 'src/app/components/wizard/wizard-image-presenter/wizard-image-presenter.component'
import { WizardVideoPresenterComponent } from 'src/app/components/wizard/wizard-video-presenter/wizard-video-presenter.component'
import { WizardBulletItemComponent } from 'src/app/components/wizard/wizard-bullet-list/wizard-bullet-item/wizard-bullet-item.component'
import { WizardImageLightbox } from 'src/app/components/wizard/wizard-image-presenter/wizard-image-lightbox/wizard-image-lightbox.component'

/***********************************************************************************************
decorators
***********************************************************************************************/

@NgModule({
    imports: [
        MaterialModule,
        RouterModule,
        CommonModule
    ],

    declarations: [
        WizardTitle,
        TranslatePipe,
        HelpComponent,
        WizardSubtitle,
        WizardComponent,
        WizardImageLightbox,
        FontSliderComponent,
        LoadingSpinnerComponent,
        WizardBulletListComponent,
        WizardBulletItemComponent,
        WizardRadioGroupComponent,
        WizardProgressBarComponent,
        FullHeightElementsComponent,
        WizardImagePresenterComponent,
        WizardVideoPresenterComponent,
    ],

    exports: [
        WizardTitle,
        RouterModule,
        CommonModule,
        TranslatePipe,
        HelpComponent,
        MaterialModule,
        WizardSubtitle,
        WizardComponent,
        WizardImageLightbox,
        FontSliderComponent,
        LoadingSpinnerComponent,
        WizardBulletListComponent,
        WizardBulletItemComponent,
        WizardRadioGroupComponent,
        WizardProgressBarComponent,
        FullHeightElementsComponent,
        WizardImagePresenterComponent,
        WizardVideoPresenterComponent,
    ]
})


/***********************************************************************************************
class Basics
***********************************************************************************************/

export class Basics { }