
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Location } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { GlobalUiService } from 'src/app/services/global-ui.service'

/***********************************************************************************************
decorators
***********************************************************************************************/

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

/***********************************************************************************************
class NotFoundComponent
***********************************************************************************************/

export class NotFoundComponent {

    // services
    /*-----------------------------------------------------------------------------------*/

    private readonly _location = inject(Location)
    private readonly _ui = inject(GlobalUiService)

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor() {

        // loggs the construction of the class
        this._ui.logMessage('component "not-found" is constructed', 0)
    }

    // public methods
    /*-----------------------------------------------------------------------------------*/

    goBack(): void {
        this._location.back()
    }
}
