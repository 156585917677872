// This file will be used during build for the production system in the cluster

export const environment = {
    
    // should this produce a production build?
	production: true,

    // is this build used in a staging environment?
	staging: true,

    // keycloak data 
	keycloak: {
        realm: 'um-apps',
        uri: 'https://sso.ghh4.unimedizin-mainz.de',
        client_id: 'um-mam-messenger'
    }
}
