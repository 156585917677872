
// (C) Copyright IBM Deutschland GmbH 2023.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { Component, inject } from '@angular/core'
import { KeycloakService } from 'keycloak-angular'
import { GlobalUiService } from 'src/app/services/global-ui.service'
import { TranslateService } from 'src/app/services/translate.service'

/***********************************************************************************************
decorators
***********************************************************************************************/

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})

/***********************************************************************************************
class HelpComponent
***********************************************************************************************/

export class HelpComponent {

    // members
    /*-----------------------------------------------------------------------------------*/

    subject: string

    // services
    /*-----------------------------------------------------------------------------------*/

    public readonly translate = inject(TranslateService)
    private readonly _location = inject(Location)
    private readonly _ui = inject(GlobalUiService)
    private readonly _route = inject(ActivatedRoute)
    private readonly _keycloakService = inject(KeycloakService)

    // constructor
    /*-----------------------------------------------------------------------------------*/

    constructor() {

        // gets subject from query param
        this.subject = this._route.snapshot.paramMap.get('subject') ?? 'ERROR'

        // loggs the construction of the class
        this._ui.logMessage('component "help" is constructed', 0)
    }

    // public
    /*-----------------------------------------------------------------------------------*/

    /** 
     * browser nav back
     */
    goBack = () => {
        this._location.back()
    }

    /** 
     * starts a call if possible
     */
    makeCall = () => {
        window.location.href = 'tel:' + this.translate.data['help-phone-content']
    }

    /** 
     * sends a mail with the qwuestion as body
     */
    sendEmail = () => {
        window.location.href = "mailto:" + this.translate.data['help-mail-content'] + "?subject=Subject&body=" + this.subject
    }
    
    /** 
     * logout with confirmation dialog
     */
    logout = () => {
        if (confirm('Möchten Sie wirklich die Sitzung beenden?')) {
            this._ui.logMessage('logout', 4)
            this._keycloakService.logout()
        }
    }
}
