
<!-- Copyright IBM Deutschland GmbH 2020.  All rights reserved. -->
<div class="error-page">
    <div>
        <h1 data-h1="Nanu?">Nanu?</h1>
        <p data-p="Hier gehts nicht weiter">Hier geht's nicht weiter</p>
        <p class="back-link" (click)="goBack()">(zurück)</p>
    </div>

</div>

<!-- <div id="particles-js"></div> -->