
// (C) Copyright IBM Deutschland GmbH 2022.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import { ChangeDetectionStrategy, Component } from '@angular/core'

/***********************************************************************************************
component
***********************************************************************************************/

/** 
* renders a simple loading spinner
* */
@Component({
  selector: 'app-font-slider',
  templateUrl: './font-slider.component.html',
  styleUrls: ['./font-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontSliderComponent {
    
    // members
    /*-----------------------------------------------------------------------------------*/

    // minimum base font value
    minValue : number = 12
    // max base font value
    maxValue : number = 24
    // stepsize
    stepValue : number = .1
    // starting font size
    baseFontSize: number = 16

    // public methods
    /*-----------------------------------------------------------------------------------*/

    /**
     * duh
     */
    changeFontSize(event: Event) {
        const inputElement = event.target as HTMLInputElement
        const newSize = inputElement.value
        this.baseFontSize = parseFloat(newSize)
        document.documentElement.style.fontSize = `${newSize}px`
    }
}
